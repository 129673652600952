require('./bootstrap');

import 'video.js/dist/video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/city/index.css';
import '@videojs/themes/dist/fantasy/index.css';
import '@videojs/themes/dist/forest/index.css';
import '@videojs/themes/dist/sea/index.css';
import '@videojs/http-streaming/dist/videojs-http-streaming.js';

import 'filepond/dist/filepond.min.css';
import * as FilePond from 'filepond';
window.FilePond = FilePond;

import videojs from 'video.js';
window.videojs = videojs;

window.$ = window.jQuery = require('jquery');
import * as moment from 'moment';
window.moment = moment;
require('jquery-countdown');

import 'pikaday/css/pikaday.css';
window.Pikaday = require('pikaday');